var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container table-block-cols",class:_vm.theme_color},[(_vm.getTable(_vm.value))?[_c('div',{staticClass:"table-wrapper"},_vm._l((_vm.getTable(_vm.value)),function(row,index_row){return _c('div',{staticClass:"table-row",class:{'header-table': _vm.value.first_row_header && index_row === 0}},[_vm._l((row),function(cell,index_cell){return [(_vm.value.table_cols[index_cell].col_type === 'link_product' && _vm.value.first_row_header && index_row
          === 0)?_c('div',{class:_vm.value.table_cols[index_cell].col_alignment},[_vm._v("\n            "+_vm._s(cell)+"\n          ")]):(_vm.value.table_cols[index_cell].col_type === 'link_product' && !_vm.value.first_row_header &&
          index_row === 0)?_c('div',{class:_vm.value.table_cols[index_cell].col_alignment},[_vm._l((_vm.getProductLinks(cell)),function(prod,idx){return [_c('nuxt-link',{attrs:{"to":_vm.localePath('/product/' + prod)}},[_vm._v(_vm._s(prod)+"\n              ")]),_vm._v(" "),(idx !== _vm.getProductLinks(cell).length-1)?_c('span',[_vm._v(" / ")]):_vm._e()]})],2):(_vm.value.table_cols[index_cell].col_type === 'link_product' && index_row !== 0)?_c('div',{class:_vm.value.table_cols[index_cell].col_alignment},[_vm._l((_vm.getProductLinks(cell)),function(prod,idx){return [_c('nuxt-link',{attrs:{"to":_vm.localePath('/product/' + prod)}},[_vm._v(_vm._s(prod)+"\n              ")]),_vm._v(" "),(idx !== _vm.getProductLinks(cell).length-1)?_c('span',[_vm._v(" / ")]):_vm._e()]})],2):((_vm.value.table_cols[index_cell].col_type === 'link_name' ||
          _vm.value.table_cols[index_cell].col_type === 'link') && (_vm.value.first_row_header && index_row
          === 0))?[(_vm.value.table_cols[index_cell].col_type === 'link_name')?_c('div',[_vm._v("\n              "+_vm._s(cell)+"\n            ")]):_vm._e()]:((_vm.value.table_cols[index_cell].col_type === 'link_name' ||
          _vm.value.table_cols[index_cell].col_type === 'link') && (!_vm.value.first_row_header && index_row === 0))?[(_vm.value.table_cols[index_cell].col_type === 'link_name')?_c('div',[_c('a',{attrs:{"href":row[index_cell+1]}},[_vm._v(_vm._s(cell))])]):_vm._e()]:((_vm.value.table_cols[index_cell].col_type === 'link_name' ||
          _vm.value.table_cols[index_cell].col_type === 'link') && (index_row !== 0))?[(_vm.value.table_cols[index_cell].col_type === 'link_name')?_c('div',[_c('a',{attrs:{"target":"_blank","href":row[index_cell+1]}},[_vm._v(_vm._s(cell))])]):_vm._e()]:_c('div',{class:_vm.value.table_cols[index_cell].col_alignment},[_vm._v(_vm._s(cell))])]})],2)}),0)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }