import Model from './base';

export class Stock extends Model {
  resource() {
    return 'catalog/stock';
  }

  defaultAttributes() {
    return {
      name: null,
      stock_contact: {},
      stock_type: null,
      name_1c: null,
      shipment_days: null,
      price_list: null,
      price_list_express: null,
    }
  }
}
