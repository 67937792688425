<template>
  <div class="content">
    <template v-for="contentObject in page.content">
      <template v-for="blockContent in contentObject.value.block_content">
        <component
          :is="getHeader(blockContent['type'])"
          class="container"
          v-html="getValue(blockContent['value'])"
        />
        <component
          :is="getComponent(blockContent['type'])"
          :value="getValue(blockContent['value'])"
          :theme="contentObject"
          :key="blockContent.id"
          :category="category"
        />
      </template>
    </template>
  </div>
</template>

<script>
import BlockImage2 from '@/components/blocks/block-image2';
import BlockImage3 from '@/components/blocks/block-image3';
import BlockImage1200 from '@/components/blocks/block-image1200';
import BlockImages from '@/components/blocks/block-images';
import BlockProductImages from '@/components/blocks/block-product-images';
import BlockPreview from '@/components/blocks/block-preview';
import BlockRawhtml from '@/components/blocks/block-rawhtml';
import BlockText from '@/components/blocks/block-text';
import BlockTextBig from '@/components/blocks/block-text-big';
import BlockVideo from '@/components/blocks/block-video';
import BlockTable from '@/components/blocks/block-table';
import BlockTableCols from '@/components/blocks/block-table-cols';
import BlockTableProducts from '@/components/blocks/block-table-products';
import BlockCategories from '@/components/blocks/block-categories';

const heads = {
  'h1': 'h1',
  'h2': 'h2',
  'h3': 'h3'
}

const comps = {
  'preview': `block-preview`,
  'text': `block-text`,
  'text_big': `block-text-big`,
  'images_set3': `block-images`,
  'image_text1_3': `block-image3`,
  'image_text1_2': `block-image2`,
  'image_1200_text': `block-image1200`,
  'product_images_set': 'block-product-images',
  'rawhtml': `block-rawhtml`,
  'video_block': 'block-video',
  'table': 'block-table',
  'table_block': 'block-table-cols',
  'table_products': 'block-table-products',
  'categories_block': 'block-categories'
}

export default {
  components: {
    BlockImage2,
    BlockImage3,
    BlockImage1200,
    BlockImages,
    BlockProductImages,
    BlockPreview,
    BlockRawhtml,
    BlockText,
    BlockTextBig,
    BlockVideo,
    BlockTable,
    BlockTableCols,
    BlockCategories,
    BlockTableProducts
  },
  props: ['page', 'category'],
  methods: {
    getHeader(typ) {
      return heads[typ] || null
    },
    getComponent(typ) {
      return comps[typ] || null
    },
    getValue(value) {
      return Object.keys(value).includes(this.$i18n.locale) ? value[this.$i18n.locale] : value;
    },
  },
}
</script>
