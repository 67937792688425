<template>
  <masked-input v-model="inputValue" :value="inputValue" type="text" :mask="mask" :show-mask="false" @input="updateField" />
</template>

<script>
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const _thousandsSeparator = ' ';

export default {
  props: ['value', 'minValue', 'maxValue'],
  data() {
    return {
      inputValue: null,
    }
  },
  watch: {
    value(value) {
      this.updateInputValue(value);
    },
  },
  computed: {
    mask() {
      return createNumberMask({
        allowLeadingZeroes: true,
        prefix: '',
        suffix: '',
        thousandsSeparatorSymbol: _thousandsSeparator,
        integerLimit: this.maxValue ? this.maxValue.toString().length : null,
      });
    },
  },
  created() {
    this.updateInputValue(this.value);
  },
  methods: {
    updateField(value) {
      value = parseInt(value.replaceAll(' ', '')) || 0;
      if (this.minValue && value < this.minValue) {
        value = this.minValue;
      }
      if (this.maxValue && value > this.maxValue) {
        value = this.maxValue;
      }
      this.updateInputValue(value);
      this.$emit('input', value);
      this.$emit('update-value');
    },
    updateInputValue(value) {
      this.inputValue = value.toString().replace(/(\d)(?=(\d{3})+$)/g, `$1${_thousandsSeparator}`);
    },
  },
};
</script>
