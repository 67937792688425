<i18n lang="yaml">
ru:
  pcs_transit: 'шт. в пути'
  delivery_deadline: 'срок поставки'
  delivery_deadline_from: 'срок поставки от'
  day_1: 'день'
  day_2: 'дня'
  day_3: 'дней'
  available: 'В наличии'
  transit: 'В пути'
  remote: 'Удаленный'
  preorder: 'Под заказ'
  add_any_quantity: 'добавьте в корзину любое количество'
  to_cart: 'В корзину'
  stock_pcs: 'Склад, шт.'
  product_added_to_cart: 'Товар добавлен в корзину.'
  product_added_to_fav: 'Товар добавлен в избранное.'
  product_removed_from_fav: 'Товар удален из избранного.'
  no: 'Нет'
  on_request: 'По запросу'
  request_price: 'Запросить цену'
en:
  pcs_transit: 'pcs. in transit'
  delivery_deadline: 'delivery deadline'
  delivery_deadline_from: 'delivery deadline from'
  day_1: 'day'
  day_2: 'days'
  day_3: 'days'
  available: 'In stock'
  transit: 'In transit'
  remote: 'Remote'
  preorder: 'Preorder'
  add_any_quantity: 'enter any quantity to cart'
  to_cart: 'Add to cart'
  stock_pcs: 'Stock, pcs.'
  product_added_to_cart: 'Product is added to cart.'
  product_added_to_fav: 'Product is added to favourites.'
  product_removed_from_fav: 'Product is removed from favourites.'
  no: 'No'
  on_request: 'On request'
  request_price: 'Request a price'
</i18n>

<template>
  <div class="product-item-wrapper">
    <div class="flex-table row product-item-wrapper-desktop" role="rowgroup" >
      <div class="flex-row fav" role="cell">
        <a href="#" @click.prevent="switchFavourite">
          <i class="svg-icon star" :class="{'star-favourite': isFavourite}"></i>
        </a>
      </div>
      <div class="flex-row articul" role="cell">
        <span>{{ product.code }}</span>
      </div>
      <div class="flex-row name" role="cell">
        <nuxt-link :to="localePath('/product/' + product.slug)">
          <!-- <span v-if="productLocaleData.name">{{ productLocaleData.name }}</span> -->
          <span v-if="productLocaleData.name" v-html="noWrapHyphen(productLocaleData.name)"></span>
          <span v-else>{{ product.code }}</span>
        </nuxt-link>
      </div>
      <div class="flex-row store" role="cell" v-if="!$noCartMode()">
        <template v-if="product.price > 0">
          <div v-for="stockType in ['available', 'transit', 'preorder']" :class="{'express': checkExpress(stockType)
          || checkPreorder(stockType)}">
            <span v-if="stockType === 'available'">{{ $t('available') }}</span>
            <span v-else-if="stockType === 'transit'" :class="{'transit': !checkExpress(stockType)}"
                  @mouseover="showTransit = !showTransit && !checkExpress(stockType)"
                  @mouseleave="showTransit = false">{{ $t('transit') }}</span>
            <span v-else-if="stockType === 'preorder'"
                  :class="{'transit': !checkExpress(stockType) && !checkPreorder(stockType)}"
                  @mouseover="showPreorder = !showPreorder && !checkExpress(stockType) && !checkPreorder(stockType)"
                  @mouseleave="showPreorder = false">{{ $t('remote') }}</span>
          </div>
          <Transition name="fade">
            <div v-if="showTransit && !productDisabled" class="transit-tooltip">
              <span>{{ product.amount_free.transit | integerPart }} {{ $t('pcs_transit') }}</span>
              <span>{{ $t('delivery_deadline') }} {{ shipmentDays('transit') }}
                {{ shipmentDays('transit') | ending([$t('day_1'), $t('day_2'), $t('day_3')], $i18n.locale) }}</span>
            </div>
          </Transition>
          <Transition name="fade">
            <div v-if="showPreorder && !productDisabled" class="transit-tooltip on-order">
              <span>{{ $t('add_any_quantity') }}</span>
              <span>{{ $t('delivery_deadline_from') }} {{ shipmentDays('preorder') }}
              {{ shipmentDays('preorder') | ending([$t('day_1'), $t('day_2'), $t('day_3')], $i18n.locale) }}</span>
            </div>
          </Transition>
        </template>
        <template v-else>
          <span>{{ $t('no') }}</span>
        </template>
      </div>

      <div class="flex-row availability" role="cell">
        <template v-if="$noCartMode()">
          In stock
        </template>
        <template v-else-if="product.price > 0">
          <div v-for="stockType in ['available', 'transit', 'preorder']" :class="{'express': checkExpress(stockType)
          || checkPreorder(stockType)}">
            <span v-if="stockType !== 'preorder'">{{ product.amount_free[stockType] | integerPart }}</span>
            <span v-else>{{ $t('preorder') }}</span>
          </div>
        </template>
        <template v-else><span>{{ $t('no') }}</span></template>
      </div>

      <div class="flex-row price" role="cell">
        <template v-if="$noCartMode()">
          <div class="price">
              <div class="price-col">
                <span>{{ $t('on_request') }}</span>
              </div>
            </div>
        </template>
        <template v-else-if="product.price > 0">
        <div v-for="stockType in ['available', 'transit', 'preorder']" :class="{'express': checkExpress(stockType) || checkPreorder(stockType)}">
          <div>
              <span class="price-digits"> {{ product.price | integerPart }}
                <span class="small">{{ product.price | fractionalPart }}</span>
              </span>
          </div>
        </div>
        </template>
        <template v-else><span>{{ $t('on_request') }}</span></template>
      </div>
      <div class="flex-row order" role="cell" v-if="!$noCartMode()">
        <div>
          <a href="#" @click.prevent="decreaseAmount"><i class="svg-icon minus icon-pointer"></i></a>
          <input-number v-model="amountCart" :value="amountCart" :min-value="0"/>
          <a href="#" @click.prevent="increaseAmount"><i class="svg-icon plus icon-pointer"></i></a>
        </div>
      </div>
      <div class="flex-row cart-col" role="cell" v-if="!$noCartMode()">
        <button v-if="product.price > 0" @click.prevent="addCart" :class="{'disabled': amountCart < 1}"><i
            class="svg-icon cart-icon"></i></button>
        <button v-else @click.prevent="addPriceRequest" :class="{'disabled': amountCart < 1}"><i
            class="svg-icon preorder"></i></button>
      </div>
    </div>


    <div class="product-item product-item-wrapper-mobile">
      <div class="fav-code">
        <a href="#" @click.prevent="switchFavourite"  v-if="!$noCartMode()">
          <i class="svg-icovn star" :class="{'star-favourite': isFavourite}"></i>
        </a>
        <span>{{ product.code }}</span>
      </div>

      <div class="name">
        <nuxt-link :to="localePath('/product/' + product.slug)">
          <span v-if="productLocaleData.name">{{ productLocaleData.name }}</span>
          <span v-else>{{ product.code }}</span>
        </nuxt-link>
      </div>

      <div class="store-price" v-if="!$noCartMode()">
        <div class="stores">
          <span>{{ $t('stock_pcs') }}</span>
          <template v-if="product.price > 0">
            <div v-for="stockType in ['available', 'transit', 'preorder']" class="store">
              <div class="store-col" :class="{'express': checkExpress(stockType) || checkPreorder(stockType)}">
                <span v-if="stockType === 'available'" class="name-store">{{ $t('available') }}</span>
                <span v-else-if="stockType === 'transit'" class="name-store">{{ $t('transit') }}</span>
                <span v-else-if="stockType === 'preorder'" class="name-store">{{ $t('remote') }}</span>
                <span v-if="stockType !== 'preorder'">{{ product.amount_free[stockType] | integerPart }}</span>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="store">
              <div class="store-col">
                <span class="name-store">{{ $t('no') }}</span>
              </div>
            </div>
          </template>
        </div>
        <div class="prices" v-if="$noCartMode()">
          <div class="prices-name">
            <span v-if="$noCartMode()">{{ $t('price') }}</span>
            <span v-else>{{ $t('price_rub') }}</span>
            <span class="upper">{{ $t('no_vat') }}</span>
          </div>
          <template v-if="product.price > 0">
          <div v-for="stockType in ['available', 'transit', 'preorder']" class="price">
            <div class="price-col" :class="{'express': checkExpress(stockType) || checkPreorder(stockType)}">
                <span class="price-digits"> {{ product.price | integerPart }}
                  <span class="small">{{ product.price | fractionalPart }}</span>
                </span>
            </div>
          </div>
          </template>
          <template v-else>
            <div class="price">
              <div class="price-col">
                <span>{{ $t('on_request') }}</span>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="order" v-if="!$noCartMode()">
        <div>
          <a href="#" @click.prevent="decreaseAmount"><i class="svg-icon minus icon-pointer"></i></a>
          <input-number v-model="amountCart" :value="amountCart" :min-value="0"/>
          <a href="#" @click.prevent="increaseAmount"><i class="svg-icon plus icon-pointer"></i></a>
        </div>

        <button v-if="product.price > 0" @click.prevent="addCart" :class="{'disabled': amountCart < 1}">{{ $t('to_cart') }}</button>
        <button v-else @click.prevent="addPriceRequest" :class="{'disabled': amountCart < 1}">{{ $t('request_price') }}</button>

      </div>


    </div>
  </div>
</template>

<script>
import {ProductFavourite} from '@/models/product';
import InputNumber from '@/components/input-number';

export default {
  components: {
    InputNumber,
  },
  props: ['product', 'express', 'hidePreorder', 'stocks'],
  data() {
    return {
      amountCart: 0,
      showTransit: false,
      showPreorder: false,
      isFavourite: false,
    }
  },
  computed: {
    productDisabled() {
      return this.product.price <= 0;
    },
    productLocaleData() {
      return this.product?.product_translations?.find(x => x.language.slug === this.$i18n.locale) || {};
    },
  },
  created() {
    this.isFavourite = this.product.is_favourite;
  },
  methods: {
    noWrapHyphen(value){
      return value ? value.replace(/(\S+)-(\S+)/g, '<nobr>$1-$2</nobr>') : ''
    },
    shipmentDays(stockType) {
      return this.stocks.find(x => x.stock_type === stockType)?.shipment_days;
    },
    checkExpress(stockType) {
      return this.express && ['transit', 'preorder'].includes(stockType);
    },
    checkPreorder(stockType) {
      return this.hidePreorder && ['preorder'].includes(stockType);
    },
    decreaseAmount() {
      if (this.amountCart > 0) {
        this.amountCart--;
      }
    },
    increaseAmount() {
      this.amountCart++;
    },
    addCart() {
      const data = {
        product: this.product.id,
        amount: this.amountCart,
        express: this.express,
        hide_preorder: this.hidePreorder,
      };
      this.$axios.$post('/order/add-cart/', data)
        .then((response) => {
          this.$store.dispatch('setCartExists');
          if (!response.amount_free && (this.express || this.hidePreorder)) {
            this.$emit('fetch-products');
          } else {
            this.product.amount_free = response.amount_free_dict;
          }
          this.$notify({
            duration: 1000,
            text: this.$t('product_added_to_cart'),
          });
        })
        .catch((e) => {
          let msg = this.$t('unknown_error');
          try {
            msg = e.response.data.detail;
          } catch (e) {
          } finally {
            this.$notify({
              duration: 5000,
              text: msg,
            });
          }
        });
    },
    addPriceRequest() {
      const data = {
        product: this.product.id,
        amount: this.amountCart,
      };
      this.$axios.$post('/order/price-request/add-price-request/', data)
        .then((response) => {
          this.$store.dispatch('setPriceRequestExists');
          this.$emit('show-price-request-modal');
        })
        .catch((e) => {
          let msg = this.$t('unknown_error');
          try {
            msg = e.response.data.detail;
          } catch (e) {
          } finally {
            this.$notify({
              duration: 5000,
              text: msg,
            });
          }
        });
    },
    switchFavourite() {
      if (this.$auth.loggedIn) {
        let fav = new ProductFavourite({product: this.product.id});
        if (this.isFavourite) {
          fav.id = this.product.id;
          fav.delete().then(() => {
            this.$store.dispatch('setFavouriteProductsExists');
            this.isFavourite = false;
            this.$notify({
              duration: 1000,
              text: this.$t('product_removed_from_fav'),
            });
            this.$emit('remove-favourite');
          });
        } else {
          fav.save().then(() => {
            this.$store.dispatch('setFavouriteProductsExists');
            this.isFavourite = true;
            this.$notify({
              duration: 1000,
              text: this.$t('product_added_to_fav'),
            });
          });
        }
      }
    },
  },
}
</script>
